import Footer from '../components/Footer';
import Header from '../components/Header';
import Logo from '../components/Logo';
import Main from '../components/Main';

function Home(){
    return(
       <>
            <Logo/>
            <Header/>
            <Main/>
            <Footer/>
            
       </>
    )
}

export default Home;