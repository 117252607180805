import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function AppRoutes(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home/> }></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;